import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { CookieBanner } from '../Common/CookieConfirmation'

const MapOverlay = ({ className, onClick, style, t }) => {
  useEffect(() => {
    if (window.localStorage.getItem(CookieBanner.googleServices) === 'true') {
      onClick()
    }
    window.addEventListener('cookie-banner:show-map', () => {
      enableMap()
    })
  }, [])

  const enableMap = () => {
    window.localStorage.setItem(CookieBanner.googleServices, true)
    onClick()
  }

  return (
    <div className={'google-map-fallback ' + className} style={style}>
      <span>{t('map.overlay.heading')}</span>
      <button onClick={enableMap}>{t('map.overlay.buttonText')}</button>
    </div>
  )
}

export default withTranslation()(MapOverlay)
