import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { modalActions } from "../components/Modals/state";
import { Header, Container, Grid, Segment } from "semantic-ui-react";
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";
import Img from "gatsby-image";

import { getFluidImage, getRawImage } from "../utils/images";
import Layout from "../components/Layouts/PageLayout";
import PageHeader from "../components/Items/PageHeader";
import MapOverlay from "../components/Map/MapOverlay";

class Contact extends Component {
  state = {
    errors: {},
    load: false,
  };

  render() {
    const { t, pageContext, i18n } = this.props;
    const cloudinary =
      "contact/Myanmar_Bagan_Bagan_-Praying-with-incense-sticks-62423-1568x1047";
    const transformation = "";
    const formUrl =
      i18n.language === "de"
        ? "https://info.tourasia.ch/l/952092/2022-01-21/wjf"
        : "https://info.tourasia.ch/l/952092/2022-01-24/xmt";
    const formIframeHeight = i18n.language === "de" ? "515" : "515";
    return (
      <div>
        <Helmet>
          <title>{t("contact.header.title") + t("htmlTitleSuffix")}</title>

          <meta name="description" content={t("contact.header.subtitle")} />
          <meta name="image" content={getRawImage({ cloudinary })} />

          <meta property="og:title" content={t("contact.header.title")} />
          <meta
            property="og:description"
            content={t("contact.header.subtitle")}
          />
          <meta property="og:image" content={getRawImage({ cloudinary })} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="tourasia" />
          <meta name="twitter:title" content={t("contact.header.title")} />
          <meta
            name="twitter:description"
            content={t("contact.header.subtitle")}
          />
          <meta name="twitter:image" content={getRawImage({ cloudinary })} />
        </Helmet>

        <Layout pageContext={pageContext}>
          <Img
            fluid={getFluidImage({ cloudinary, transformation, ar: 2.5 })}
            alt={t("contact.image.alt")}
          />

          <PageHeader title={t("contact.pageTitle")} />

          <Segment vertical padded="very" basic style={{ width: "100%" }}>
            <Container>
              <Grid style={{ maxWidth: "650px", margin: "auto" }}>
                <Grid.Row computer={16} tablet={16} mobile={16}>
                  <Segment vertical basic>
                    <Header as="h2" color="red">
                      {t("contact.openningHours.title")}
                    </Header>
                    <Trans i18nKey={"contact.openningHours.text"}>
                      <p>
                        Wir sind von Montag bis Freitag, 09.00 bis 18.00 Uhr,
                        für Sie da.
                      </p>
                      <p>
                        Gerne beraten wir Sie persönlich. Uns ist wichtig, dass
                        Sie Ihre Reise mit einem Experten für Ihre
                        Wunschdestination planen können. Vereinbaren Sie deshalb
                        mit uns telefonisch oder per E-Mail einen Termin. Wir
                        freuen uns auf Sie!
                      </p>
                    </Trans>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment vertical basic>
                    <Header as="h2" color="red">
                      {t("contact.address.title")}
                    </Header>
                    {t("contact.address.address")
                      .split("/n")
                      .map((line, key) => (
                        <div key={key}>{line}</div>
                      ))}
                    <br />
                    {t("contact.address.phone1Label")}{" "}
                    <a href={`tel:${t("contact.address.phone1")}`}>
                      {t("contact.address.phone1")}
                    </a>{" "}
                    <br />
                    {t("contact.address.phone2Label")}{" "}
                    <a href={`tel:${t("contact.address.phone2")}`}>
                      {t("contact.address.phone2")}
                    </a>{" "}
                    <br />
                    {t("contact.address.faxLabel")}{" "}
                    <a href={`fax:${t("contact.address.fax")}`}>
                      {t("contact.address.fax")}
                    </a>{" "}
                    <br />
                    <br />
                    {t("contact.address.email")}:{" "}
                    <a href={"mailto: " + t("contact.address.emailLink")}>
                      {t("contact.address.emailLabel")}
                    </a>
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment vertical basic style={{ width: "100%" }}>
                    <Header as="h2" color="red">
                      {t("contact.contactUs")}
                    </Header>
                    <iframe
                      title="pardot_form"
                      src={formUrl}
                      width="100%"
                      height={formIframeHeight}
                      type="text/html"
                      frameborder="0"
                      allowTransparency="true"
                    />
                  </Segment>
                </Grid.Row>
                <Grid.Row>
                  <Segment
                    vertical
                    basic
                    style={{
                      width: "100%",
                    }}
                  >
                    <Header as="h2" color="red">
                      {t("contact.location")}
                    </Header>
                    <div
                      style={{
                        width: "100%",
                        minHeight: "450px",
                        position: "relative",
                      }}
                    >
                      {(this.state.load && (
                        <iframe
                          style={{ border: "0" }}
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2699.9626860149187!2d8.584928616142374!3d47.41266897917223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa044d2fa5595%3A0x1adb38c93119d866!2sTourasia+Roemer+AG!5e0!3m2!1sde!2sch!4v1541104763419"
                          width="100%"
                          title="Google map"
                          height="450"
                        />
                      )) || (
                        <MapOverlay
                          onClick={() => this.setState({ load: true })}
                        />
                      )}
                    </div>
                  </Segment>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Layout>
      </div>
    );
  }
}
/**************************************************************
 * REDUX
 **************************************************************/
const mapDispatchToProps = {
  toggleModal: modalActions.toggleModal,
};

export default withTranslation()(
  connect(
    null,
    mapDispatchToProps
  )(Contact)
);
